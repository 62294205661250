import React from "react";
import axios from "axios";
import { BASE_API_URL } from "store/Me";
import { useQuery } from "@tanstack/react-query";
import { PageControl } from "components/shared/PageControl";
import { defaultPageSize } from "helpers";

export default function SiteKeyActivity({
  siteId,
  credentialId,
}: {
  siteId: string;
  credentialId: string;
}) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const { data, isError } = useGetSiteKeyActivity(
    siteId,
    credentialId,
    page,
    pageSize
  );

  if (isError) {
    return <div>Error loading activity</div>;
  }

  if (data) {
    if (data.totalCount === 0) {
      return <div>No activity found</div>;
    }

    return (
      <div style={{ paddingBottom: 20 }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Lock Id</th>
              <th>Lock Name</th>
              <th>Category</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((activity) => (
              <tr key={`${activity.timestamp}-${activity.lockId}`}>
                <td>{new Date(activity.timestamp).toLocaleString()}</td>
                <td>{activity.lockId}</td>
                <td>{activity.lockName}</td>
                <td>{activity.lockActivityCategory}</td>
                <td>{activity.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ padding: "10px 0" }}>
          <PageControl
            onNext={() => setPage(page + 1)}
            onPrev={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            setPageSize={(pageSize) => {
              setPageSize(pageSize);
              setPage(0);
            }}
            pageSize={pageSize || defaultPageSize}
            currentPage={page}
            disablePrev={page === 0}
            disableNext={data?.totalCount <= (page + 1) * pageSize}
          />
        </div>
      </div>
    );
  }

  return <div>loading...</div>;
}

interface SiteKeyActivityPaginatedResponse {
  totalCount: number;
  data: SiteKeyActivityResponse[];
}

interface SiteKeyActivityResponse {
  timestamp: string;
  lockId: string;
  lockName: string;
  lockActivityCategory: string;
  badgeId: string;
  credential: string;
  details: string;
}

async function getSiteKeyActivity(
  siteId: string,
  credentialId: string,
  page: number,
  pageSize: number
) {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("pageSize", pageSize.toString());

  const result = await axios.get<SiteKeyActivityPaginatedResponse>(
    `${BASE_API_URL}/api/sites/${siteId}/physicalKeys/${credentialId}/lock-activity?${params.toString()}`
  );

  return result.data;
}

function useGetSiteKeyActivity(
  siteId: string,
  credentialId: string,
  page: number,
  pageSize: number
) {
  return useQuery({
    queryKey: [
      "sites",
      "keys",
      "activity",
      siteId,
      credentialId,
      page,
      pageSize,
    ],
    queryFn: () => getSiteKeyActivity(siteId, credentialId, page, pageSize),
  });
}
