import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function LockDatabaseHistory({ lockId }: { lockId: string }) {
  const { data, isError } = useGetLockDatabaseHistory(lockId);

  if (isError) {
    return <div>Error loading database history</div>;
  }

  if (data) {
    if (data.length === 0) {
      return <div>No records found</div>;
    }

    return (
      <div style={{ paddingBottom: 20 }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Completed Timestamp</th>
              <th>Status</th>
              <th>Successful</th>
              <th>Full Update</th>
              <th>Records</th>
              <th>Manual</th>
              <th>Next Database Version Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {data.map((history) => (
              <tr key={history.id}>
                <td>{history.timestamp}</td>
                <td>{history.completedTimestamp}</td>
                <td>{history.status}</td>
                <td>{history.successful ? "Yes" : "No"}</td>
                <td>{history.fullUpdate ? "Yes" : "No"}</td>
                <td>{history.records}</td>
                <td>{history.isManual ? "Yes" : "No"}</td>
                <td>{new Date(history.nxtDbVerTs).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div>loading...</div>;
}

interface LockDatabaseHistoryResponse {
  id: string;
  timestamp: string;
  completedTimestamp: string;
  status: string;
  successful: boolean;
  fullUpdate: boolean;
  records: number;
  isManual: boolean;
  nxtDbVerTs: number;
  partials: {
    id: string;
    timestamp: string;
    completedTimestamp: string;
    status: string;
    successful: boolean;
    addedRecords: number;
    updatedRecords: number;
    deletedRecords: number;
    nxtDbVerTs: number;
  }[];
}

async function getLockDatabaseHistory(lockId: string) {
  const response = await axios.get<LockDatabaseHistoryResponse[]>(
    `${BASE_API_URL}/api/Locks/${lockId}/database-history`
  );
  return response.data;
}

function useGetLockDatabaseHistory(lockId: string) {
  return useQuery({
    queryKey: ["locks", "details", "database", lockId],
    queryFn: () => getLockDatabaseHistory(lockId),
  });
}
