import React from "react";
import { useQuery } from "@tanstack/react-query";
import { GatewayInfoResponse, LockResponse } from "api/ApiClient";
import axios from "axios";
import { BASE_API_URL } from "store/Me";
import DeviceTypeFirmware from "./DeviceFirmware";

export default function FirmwareContainer({ lock }: { lock: LockResponse }) {
  const { data, isLoading } = useGetGateway(
    lock.id!,
    lock.onlineStatus !== "Offline"
  );

  return (
    <ConnectedLockFirmware
      lock={lock}
      gatewayInfo={data}
      isLoading={isLoading}
    />
  );
}

function ConnectedLockFirmware({
  lock,
  isLoading,
  gatewayInfo,
}: {
  isLoading: boolean;
  lock: LockResponse;
  gatewayInfo: GatewayInfoResponse | undefined;
}) {
  return (
    <div className="col-md-12">
      <div style={{ marginTop: 24 }} className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Connected Lock Firmware</h3>
        </div>
        <div className="panel-body">
          {lock.onlineStatus === "Offline" ? (
            <div className="alert alert-danger">Lock is offline</div>
          ) : isLoading ? (
            <div>
              <em>Checking for available firmware updates</em>
            </div>
          ) : (
            <>
              <DeviceTypeFirmware
                siteId={lock.site?.id}
                modelType={lock.deviceType}
              />
              {gatewayInfo?.information?.deviceTypeFirmware?.map((dtf) => {
                return (
                  <DeviceTypeFirmware
                    key={dtf.firmwareVersion}
                    siteId={lock.site?.id}
                    modelType={dtf.modelType}
                  />
                );
              })}
            </>
          )}
          <div
            style={{
              marginTop: 16,
              paddingTop: 16,
              borderTop: `1px solid gray`,
            }}
          >
            <h5>Locks</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <td>Link</td>
                  <td>Name</td>
                  <td>S/N</td>
                  <td>Retries</td>
                  <td>Firmware Version</td>
                  <td>Transfer</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {gatewayInfo?.locks?.map((lock) => {
                  const clf = gatewayInfo?.information?.deviceTypeFirmware
                    ?.find((dtf) => dtf.modelType === lock.modelType)
                    ?.connectedLockFirmware?.find(
                      (clf) => clf.deviceId === lock.deviceId
                    );

                  return (
                    <tr key={lock.deviceId}>
                      <td>{lock.linkId}</td>
                      <td>{lock.deviceName}</td>
                      <td>{lock.deviceId}</td>
                      <td>{clf?.retries}</td>
                      <td>{clf?.firmwareVersion}</td>
                      <td>{clf?.transferPercentage}</td>
                      <td>{clf?.updateStatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

async function getGateway(lockId: string) {
  const result = await axios.get<GatewayInfoResponse>(
    `${BASE_API_URL}/api/Locks/${lockId}/gateway`
  );
  return result.data;
}

function useGetGateway(lockId: string, enabled: boolean) {
  return useQuery({
    queryKey: ["locks", "gateway", lockId],
    queryFn: () => getGateway(lockId),
    enabled,
  });
}
