import axios from "axios";
import React from "react";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { BASE_API_URL } from "store/Me";
import { useQuery } from "@tanstack/react-query";
import { LockResponse, SiteSystemType } from "api/ApiClient";
import LockActivity from "./LockActivity";
import LockGuests from "./LockGuests";
import LockDatabaseHistory from "./LockDatabaseHistory";
import LockAutomation from "./LockAutomation";
import LockEvents from "./LockEvents";
import ConnectedLockFirmware from "./components/ConnectedLockFirmware";
import LockDetails from "./components/LockDetails";

export default function AdminLockPage() {
  const params = useParams<{ lockId: string }>();
  const { data, isError } = useGetLock(params.lockId!);
  const { url } = useRouteMatch();

  if (isError) {
    return <div>Error loading lock</div>;
  }

  if (data) {
    return (
      <div style={{ paddingTop: 36 }}>
        <Link to="/admin/locks2">Back to Locks</Link>
        <div className="row">
          <LockDetails lock={data} />
          {data.site?.account?.systemType === SiteSystemType.SchlageEngage ? (
            <HardwareInformation firmwareLock={data.firmwareLock ?? ""} />
          ) : null}
        </div>
        {data.deviceType === "gwe" ? (
          <div className="row">
            <ConnectedLockFirmware lock={data} />
          </div>
        ) : (
          <>
            <Navigation siteSystemType={data.site?.account?.systemType} />
            <div style={{ paddingTop: 12 }} className="row">
              <div className="col-xs-10">
                <Switch>
                  <Route path={`${url}/activity`}>
                    <LockActivity lockId={data.id!} />
                  </Route>
                  <Route path={`${url}/guests`}>
                    <LockGuests lockId={data.id!} />
                  </Route>
                  <Route path={`${url}/database-history`}>
                    <LockDatabaseHistory lockId={data.id!} />
                  </Route>
                  <Route path={`${url}/automations`}>
                    <LockAutomation lockId={data.id!} />
                  </Route>
                  <Route path={`${url}/events`}>
                    <LockEvents lockId={data.id!} />
                  </Route>
                  <Redirect
                    to={
                      data.site?.account?.systemType ===
                      SiteSystemType.SchlageEngage
                        ? `${url}/activity`
                        : `${url}/guests`
                    }
                  />
                </Switch>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return <div>loading...</div>;
}

function getNavigationLinks(siteSystemType: SiteSystemType | undefined) {
  let navLinks = [];

  if (siteSystemType === SiteSystemType.SchlageEngage) {
    navLinks.push({ to: "activity", label: "Activity" });
  }

  navLinks.push({ to: "guests", label: "Guests" });

  if (siteSystemType === SiteSystemType.SchlageEngage) {
    navLinks.push({ to: "database-history", label: "Database history" });
    navLinks.push({ to: "automations", label: "Automations" });
    navLinks.push({ to: "events", label: "Events" });
  }

  return navLinks;
}

function Navigation({
  siteSystemType,
}: {
  siteSystemType: SiteSystemType | undefined;
}) {
  const navLinks = getNavigationLinks(siteSystemType);
  const { url } = useRouteMatch();

  return (
    <div style={{ marginTop: 20 }}>
      <nav style={{ display: "flex", alignItems: "center", gap: 12 }}>
        {navLinks.map(({ to, label }) => (
          <NavLink
            key={to}
            to={`${url}/${to}`}
            activeStyle={{
              fontWeight: 600,
              backgroundColor: "rgb(95, 165, 249)",
              padding: "4px 8px",
              color: "white",
              borderRadius: 4,
              textDecoration: "none",
            }}
          >
            {label}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

function HardwareInformation({ firmwareLock }: { firmwareLock: string }) {
  return (
    <div className="col-md-6">
      <div style={{ marginTop: 24 }} className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Firmware Information</h3>
        </div>
        <div className="panel-body">
          <div>
            <label>Hardware Information</label>
            <p>{firmwareLock}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

async function getLock(lockId: string) {
  const result = await axios.get<LockResponse>(
    `${BASE_API_URL}/api/locks/${lockId}`
  );
  return result.data;
}

function useGetLock(lockId: string) {
  return useQuery({
    queryKey: ["locks", "details", lockId],
    queryFn: () => getLock(lockId),
  });
}
