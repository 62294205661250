import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function GuestLockActivity({ guestId }: { guestId: string }) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const { data, isError } = useGetGuestLockActivity(guestId, page, limit);

  if (isError) {
    return <div>There as an error loading key requests.</div>;
  }

  if (data) {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Lock Name</th>
              <th>Lock Activity Category</th>
              <th>Credential Number</th>
              <th>Credential Type</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((lock) => (
              <tr key={lock.lockId}>
                <td>{new Date(lock.timestamp).toLocaleString()}</td>
                <td>{lock.lockName}</td>
                <td>{lock.lockActivityCategory}</td>
                <td>{lock.credentialNumber}</td>
                <td>{lock.credentialType}</td>
                <td>{lock.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 12,
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <li>
            <span>Rows per page: </span>
            <select
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value, 10))}
            >
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </li>
          <li>
            <button
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 1}
            >
              Prev
            </button>
          </li>
          <li className="active">
            <span>
              Page{" "}
              <select
                value={page}
                onChange={(e) => setPage(parseInt(e.target.value, 10))}
              >
                {Array.from(
                  { length: Math.ceil(data.totalCount / limit) },
                  (_, i) => (
                    <option key={i + 1} value={i}>
                      {i + 1}
                    </option>
                  )
                )}
              </select>{" "}
              of {Math.ceil(data.totalCount / limit)}
            </span>
          </li>
          <li>
            <button
              onClick={() => setPage((prev) => prev + 1)}
              disabled={data.totalCount / limit < page}
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    );
  }

  return <div>loading...</div>;
}

export interface GuestLockActivityResponse {
  totalCount: number;
  data: {
    lockId: string;
    timestamp: string;
    lockName: string;
    lockActivityCategory: string;
    credentialNumber: string;
    credentialType: string;
    details: string;
  }[];
}

async function getGuestLockActivity(
  guestId: string,
  page: number,
  limit: number
) {
  const url = `${BASE_API_URL}/api/guest/${guestId}/lock-activity?page=${page}&pageSize=${limit}`;
  const response = await axios.get<GuestLockActivityResponse>(url);

  return response.data;
}

function useGetGuestLockActivity(guestId: string, page: number, limit: number) {
  return useQuery({
    queryKey: ["guests", "lock-activity", guestId, page, limit],
    queryFn: () => getGuestLockActivity(guestId, page, limit),
  });
}
