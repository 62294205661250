import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Glyphicon, Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./NavMenu.css";

import { connect } from "react-redux";
import { ApplicationState } from "../store";
import * as Auth0State from "../store/Auth0";
import { LoginForm } from "./shared/LoginForm";
import TenantPicker from "./Tenants/TenantPicker";
import { MeState } from "../store/Me";
import { isAdmin } from "../helpers";
import Logo from "./logo.png";
import { Box } from "@material-ui/core";

type RouteProps = RouteComponentProps<{}>;
type OwnProps = RouteProps;
type Props = OwnProps & {
  auth0: Auth0State.Auth0State;
  me: MeState;
};

const NavMenuComponent = (props: Props) => {
  const {
    auth0: {
      session: { accessToken, isLoading },
    },
    me: { user },
  } = props;

  const [expandable, setExpandable] = React.useState(false);

  if (isLoading) return null;

  console.log("user data ", user);

  return (
    <Navbar inverse fixedTop fluid collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/">
            <Box display={"flex"} alignItems="center">
              <img
                src={Logo}
                alt="logo"
                width={50}
                height={50}
                style={{ marginRight: "10px" }}
              />
              <p>Connect Dashboard</p>
            </Box>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav>
          {!!accessToken && (
            <NavItem>
              {
                //@ts-ignore
                <TenantPicker history={props.history} />
              }
            </NavItem>
          )}
          {isAdmin(props.me.user) && (
            <React.Fragment>
              <LinkContainer to={"/"} exact>
                <NavItem>
                  <Glyphicon glyph="home" /> Home
                </NavItem>
              </LinkContainer>
              <React.Fragment>
                <NavItem onClick={() => setExpandable(!expandable)}>
                  <div className="admin-container">
                    <span>
                      <Glyphicon glyph="user" /> Admin{" "}
                    </span>
                    <Glyphicon
                      glyph={expandable ? "chevron-down" : "chevron-up"}
                    />
                  </div>
                </NavItem>

                {expandable && (
                  <React.Fragment>
                    {props.me.user && props.me.user.superUser && (
                      <LinkContainer to={"/admin/tenants"} exact>
                        <NavItem>
                          <Glyphicon glyph="cog" /> Tenants
                        </NavItem>
                      </LinkContainer>
                    )}
                    <LinkContainer to={"/admin/accounts"} exact>
                      <NavItem>
                        <Glyphicon glyph="cog" /> Accounts
                      </NavItem>
                    </LinkContainer>

                    <LinkContainer to={"/admin/clientUsers"} exact>
                      <NavItem>
                        <Glyphicon glyph="cog" /> Users
                      </NavItem>
                    </LinkContainer>

                    <LinkContainer to={"/admin/siteUsers"} exact>
                      <NavItem>
                        <Glyphicon glyph="cog" /> Site Users
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to={"/admin/sharedCredentials"} exact>
                      <NavItem>
                        <Glyphicon glyph="cog" /> Shared Credentials
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to={"/allInvites"} exact>
                      <NavItem>
                        <span
                          role="img"
                          aria-label="send mobile key"
                          style={{ marginRight: "10px" }}
                        >
                          🔗
                        </span>
                        {"  "}
                        Invites
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to={"/openDoor"} exact>
                      <NavItem>
                        <Glyphicon glyph="lock" /> Open Door
                      </NavItem>
                    </LinkContainer>
                  </React.Fragment>
                )}
              </React.Fragment>
              <div className="admin-border-top"></div>
              <LinkContainer to={"/admin/sites"} exact>
                <NavItem>
                  <Glyphicon glyph="cog" /> Sites
                </NavItem>
              </LinkContainer>
              <LinkContainer to={"/admin/accessControlGroups"} exact>
                <NavItem>
                  <Glyphicon glyph="cog" /> Access Control Groups
                </NavItem>
              </LinkContainer>
              <LinkContainer to={"/admin/locks"} exact>
                <NavItem>
                  <Glyphicon glyph="cog" /> Locks
                </NavItem>
              </LinkContainer>

              <LinkContainer to={"/keyRequests"} exact>
                <NavItem>
                  <Glyphicon glyph="list" /> Access Log
                </NavItem>
              </LinkContainer>

              {user?.superUser && (
                <LinkContainer to={"/admin/guests"} exact>
                  <NavItem>
                    <Glyphicon glyph="user" /> Guests
                  </NavItem>
                </LinkContainer>
              )}
              <LinkContainer to="/admin/locks2" exact>
                <NavItem>
                  <Glyphicon glyph="cog" /> Locks V2
                </NavItem>
              </LinkContainer>
            </React.Fragment>
          )}

          {!!accessToken && (
            <NavItem>
              <LoginForm />
            </NavItem>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export const NavMenu = connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    auth0: state.auth0,
    me: state.me,
    // seedDataData: state.seedData,
    routing: (state as any).routing,
  })
  // SeedDataState.actionCreators
)(NavMenuComponent);
