import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function GuestCredentials({ guestId }: { guestId: string }) {
  const { data, isError } = useGetGuestCredentials(guestId);

  if (isError) {
    return <div>There as an error loading credentials.</div>;
  }

  if (data) {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Site</th>
              <th>Credential</th>
              <th>Timestamps</th>
            </tr>
          </thead>
          <tbody>
            {data.map((credential) => (
              <tr key={credential.id}>
                <td>{credential.type}</td>
                <td>
                  <div>{credential.site?.name}</div>
                  <div>{credential.site?.id}</div>
                </td>
                <td>
                  {credential.credential ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: 250,
                        overflow: "hidden",
                      }}
                      title={credential.credential}
                    >
                      Credential: {credential.credential}
                    </div>
                  ) : null}
                  {credential.credentialId ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: 250,
                        overflow: "hidden",
                      }}
                      title={credential.credentialId}
                    >
                      Credential Id: {credential.credentialId}
                    </div>
                  ) : null}
                </td>
                <td>
                  {credential.claimedTimestamp && (
                    <div>
                      Claimed:{" "}
                      {credential.claimedTimestamp
                        ? new Date(credential.claimedTimestamp).toLocaleString()
                        : ""}
                    </div>
                  )}
                  {credential.refreshedTimestamp && (
                    <div>
                      Refreshed:{" "}
                      {credential.refreshedTimestamp
                        ? new Date(
                            credential.refreshedTimestamp
                          ).toLocaleString()
                        : ""}
                    </div>
                  )}
                  {credential.deletedTimestamp && (
                    <div>
                      Deleted:{" "}
                      {credential.deletedTimestamp
                        ? new Date(credential.deletedTimestamp).toLocaleString()
                        : ""}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div>loading...</div>;
}

export interface GuestCredentialResponse {
  id: string;
  site: {
    id: string;
    name: string;
  } | null;
  type: string;
  credentialId: string;
  credential: string;
  preliminaryCredential: string;
  claimedTimestamp: string | null;
  refreshedTimestamp: string | null;
  deletedTimestamp: string | null;
}

async function getGuestCredentials(guestId: string) {
  const url = `${BASE_API_URL}/api/guest/${guestId}/credentials`;
  const response = await axios.get<GuestCredentialResponse[]>(url);

  return response.data;
}

function useGetGuestCredentials(guestId: string) {
  return useQuery({
    queryKey: ["guests", "credentials", guestId],
    queryFn: () => getGuestCredentials(guestId),
  });
}
