import React, { useState } from "react";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BASE_API_URL } from "store/Me";
import { PageControl } from "components/shared/PageControl";
import SearchInput from "components/shared/SearchInput";
import { defaultPageSize } from "helpers";
import SiteKey from "./Key";

function SiteKeys() {
  const params = useParams<{ siteId: string }>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");
  const isSuperUser = useSelector(
    (state: ApplicationState) => state.me.user?.superUser
  );

  if (!isSuperUser) {
    return (
      <div style={{ marginTop: 24, fontWeight: 500, color: "red" }}>
        Access Denied: You do not have permission to access this page
      </div>
    );
  }

  return (
    <KeysList
      siteId={params.siteId}
      search={search}
      setSearch={setSearch}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
    />
  );
}

function KeysList({
  siteId,
  search,
  page,
  pageSize,
  setSearch,
  setPage,
  setPageSize,
}: {
  siteId: string;
  search: string;
  page: number;
  pageSize: number;
  setSearch: (search: string) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
}) {
  const { data, isError, isLoading } = useGetSiteKeys(
    siteId,
    search,
    page,
    pageSize
  );

  return (
    <div style={{ paddingTop: 36 }}>
      <Link to="/admin/sites">Back to Sites</Link>
      <h3>Keys</h3>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {isError ? (
          <div>There was an error</div>
        ) : isLoading ? (
          <div>loading...</div>
        ) : data ? (
          <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Credential</th>
                  <th>Badge ID</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>Currently Assigned To</th>
                  <th>Last Encoded</th>
                  <th>Last Updated By</th>
                </tr>
              </thead>
              <tbody>
                {data.map((siteKey) => (
                  <tr key={siteKey.credential}>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <Link
                          to={{
                            pathname: `keys/${siteKey.credential}`,
                            state: { guest: siteKey },
                          }}
                        >
                          {siteKey.credential}
                        </Link>
                      </div>
                    </td>
                    <td>{siteKey.badgeId}</td>
                    <td>{new Date(siteKey.validFrom).toLocaleString()}</td>
                    <td>{new Date(siteKey.validTo).toLocaleString()}</td>
                    <td>{siteKey.guestId}</td>
                    <td>
                      {new Date(siteKey.encodedTimestamp).toLocaleString()}
                    </td>
                    <td>Unknown</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <PageControl
                onNext={() => setPage(page + 1)}
                onPrev={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                setPageSize={setPageSize}
                pageSize={pageSize || defaultPageSize}
                currentPage={page}
                isLoading={isLoading}
                disablePrev={page === 0}
                disableNext={data.length < pageSize}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export interface SiteKeyResponse {
  badgeId: string;
  credential: string;
  encodedTimestamp: string;
  format: string;
  guestId: string;
  jaguarSectors: null;
  physicalKeyGroupId: null;
  siteId: string;
  validFrom: string;
  validTo: string;
}

async function getKeys(
  siteId: string,
  search: string,
  page: number,
  pageSize: number
) {
  const urlParams = new URLSearchParams();
  urlParams.append("searchString", search);
  urlParams.append("page", page.toString());
  urlParams.append("pageSize", pageSize.toString());

  const result = await axios.get<SiteKeyResponse[]>(
    `${BASE_API_URL}/api/sites/${siteId}/physicalKeys?${urlParams.toString()}`
  );
  return result.data;
}

function useGetSiteKeys(
  siteId: string,
  search: string,
  page: number,
  pageSize: number
) {
  return useQuery({
    queryKey: ["keys", siteId, search, page, pageSize],
    queryFn: () => getKeys(siteId, search, page, pageSize),
  });
}

export default function SiteKeyRoutes() {
  return (
    <Switch>
      <Route path="/admin/sites/:siteId/keys/:credentialId">
        <SiteKey />
      </Route>
      <Route exact path="/admin/sites/:siteId/keys">
        <SiteKeys />
      </Route>
    </Switch>
  );
}
