import React from "react";
import { Switch, Route } from "react-router-dom";

import Guests from "../Guests";
import GuestDetails from "../GuestDetails";
import OnboardGuest from "../OnboardGuest";
import AccessSchedules from "../AccessSchedules";
import SiteEdit from "./SiteEdit";
import AdminSites from "../Admin/AdminSites";
import SiteKeys from "./Keys";
import SiteKeysAudit from "./KeysAudit";

export default function Sites() {
  return (
    <Switch>
      <Route exact path="/admin/sites" component={AdminSites} />
      <Route exact path="/admin/sites/:siteId/guests" component={Guests} />
      <Route
        exact
        path="/admin/sites/:siteId/guests/:guestId"
        component={GuestDetails}
      />
      <Route
        exact
        path="/admin/sites/:siteId/onboardguest"
        component={OnboardGuest}
      />
      <Route
        exact
        path="/admin/sites/:siteId/accessschedules"
        component={AccessSchedules}
      />
      <Route exact path="/admin/sites/:siteId/edit" component={SiteEdit} />
      <Route
        exact
        path="/admin/sites/:siteId/keys-audit"
        component={SiteKeysAudit}
      />
      <Route path="/admin/sites/:siteId/keys" component={SiteKeys} />
    </Switch>
  );
}
