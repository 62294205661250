import { useQuery } from "@tanstack/react-query";
import {
  AccessControlDoorType,
  LockResponse,
  SiteResponse,
} from "api/ApiClient";
import axios from "axios";
import { PageControl } from "components/shared/PageControl";
import SearchInput from "components/shared/SearchInput";
import { defaultPageSize } from "helpers";
import React from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL } from "store/Me";
import useDebounce from "utils/useDebounce";

export default function AdminLocks({
  search,
  setSearch,
  page,
  setPage,
  pageSize,
  setPageSize,
  doorType,
  setDoorType,
  siteId,
  setSiteId,
}: {
  search: string;
  setSearch: (search: string) => void;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  doorType: string;
  setDoorType: (doorType: string) => void;
  siteId: string;
  setSiteId: (siteId: string) => void;
}) {
  const { data: sites } = useGetSites();
  const debouncedSearchTerm = useDebounce(search, 500);
  const { data, isLoading, isError } = useGetLocks(
    debouncedSearchTerm,
    page,
    pageSize,
    doorType,
    siteId
  );
  return (
    <div>
      <h3>Locks</h3>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div style={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
            <div className="form-group">
              <label htmlFor="siteId">Sites</label>
              <select
                className="form-control"
                id="siteId"
                value={siteId}
                onChange={(e) => setSiteId(e.target.value)}
              >
                <option value="">All Sites</option>
                {sites?.map((site) => (
                  <option key={site.id} value={site.id}>
                    {site.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="doorType">Door Type</label>
              <select
                className="form-control"
                value={doorType}
                onChange={(e) => setDoorType(e.target.value)}
              >
                <option value="">All Door Types</option>
                {Object.keys(AccessControlDoorType)
                  .filter((k) => isNaN(Number(k)))
                  .map((k, i) => (
                    <option key={i} value={k}>
                      {k}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        {isError ? (
          <div>There was an error</div>
        ) : isLoading ? (
          <div>loading...</div>
        ) : data ? (
          <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Lock Name</th>
                  <th>Site</th>
                  <th>Door Type</th>
                  <th>SystemLockId</th>
                </tr>
              </thead>
              <tbody>
                {data.map((lock) => (
                  <tr key={lock.id}>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/admin/locks2/${lock.id}`,
                            state: { guest: lock },
                          }}
                        >
                          {lock.name}
                        </Link>
                        <LockStatus
                          lastSeenTimeStamp={lock.lastSeenTimeStamp}
                        />
                      </div>
                    </td>
                    <td>{lock.site?.name}</td>
                    <td>{lock.doorType}</td>
                    <td>{lock.systemLockId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <PageControl
                onNext={() => setPage(page + 1)}
                onPrev={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
                setPageSize={setPageSize}
                pageSize={pageSize || defaultPageSize}
                currentPage={page}
                isLoading={isLoading}
                disablePrev={page === 0}
                disableNext={data.length < pageSize}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function LockStatus({
  lastSeenTimeStamp,
}: {
  lastSeenTimeStamp: Date | undefined;
}) {
  let badgeClass = "badge";
  let badgeText = "online";
  if (lastSeenTimeStamp) {
    let now = new Date().getTime();
    let lockLastSeen = new Date(lastSeenTimeStamp + "Z").getTime();
    let difference = (now - lockLastSeen) / 1000 / 60;
    if (difference <= 5) {
      badgeClass += " alert-success";
    } else if (difference <= 20) {
      badgeClass += " alert-warning";
    } else {
      badgeClass += " alert-danger";
      badgeText = "offline";
    }
  }

  if (lastSeenTimeStamp) {
    return (
      <span
        className={badgeClass}
        data-toggle="tooltip"
        title={
          "Last Seen: " + new Date(lastSeenTimeStamp + "Z").toLocaleString()
        }
      >
        {badgeText}
      </span>
    );
  }

  return null;
}

async function getLocks(
  searchString: string,
  page: number,
  pageSize: number,
  doorType: string,
  siteId: string
) {
  const urlParams = new URLSearchParams();
  urlParams.append("searchString", searchString);
  urlParams.append("page", page.toString());
  urlParams.append("pageSize", pageSize.toString());
  urlParams.append("doorType", doorType);
  urlParams.append("siteId", siteId);
  const url = `${BASE_API_URL}/api/locks?${urlParams.toString()}`;

  const response = await axios.get<LockResponse[]>(url);

  return response.data;
}

function useGetLocks(
  search: string,
  page: number,
  pageSize: number,
  doorType: string,
  siteId: string
) {
  return useQuery({
    queryKey: ["locks", search, page, pageSize, doorType, siteId],
    queryFn: () => getLocks(search, page, pageSize, doorType, siteId),
  });
}

async function getSites() {
  const result = await axios.get<SiteResponse[]>(
    `${BASE_API_URL}/api/Sites?page=0&pageSize=100&searchString=`
  );

  return result.data;
}

function useGetSites() {
  return useQuery({
    queryKey: ["sites"],
    queryFn: getSites,
  });
}
