import React from "react";
import axios from "axios";
import { BASE_API_URL } from "store/Me";
import { useQuery } from "@tanstack/react-query";
import { PageControl } from "components/shared/PageControl";
import { defaultPageSize } from "helpers";
import Modal from "react-bootstrap-modal";
import { Link, useLocation, useParams } from "react-router-dom";

export default function SiteKeysAudit() {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const params = useParams<{ siteId: string }>();
  const location = useLocation();
  const locationState = location.state as { site: any };
  const { site } = locationState || {};
  const { data, isError } = useGetSiteKeyAudit(params.siteId, page, pageSize);

  if (isError) {
    return <div>Error loading activity</div>;
  }

  if (data) {
    if (data.totalCount === 0) {
      return <div>No audits found</div>;
    }

    return (
      <div style={{ paddingTop: 36 }}>
        <Link to={`/admin/sites`}>Back to Sites</Link>
        <div style={{ fontSize: 24, fontWeight: 600, marginTop: 12 }}>
          {site.name}
        </div>
        <div style={{ fontSize: 14, fontWeight: 400 }}>{site.id}</div>
        <div style={{ paddingTop: 12, paddingBottom: 20 }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Credential</th>
                <th>Badge Id</th>
                <th>Event</th>
                <th>Actor</th>
                <th>Old Assignment</th>
                <th>New Assignment</th>
                <th>Encoding</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map((audit) => (
                <tr key={audit.id}>
                  <td>{new Date(audit.timestamp).toLocaleString()}</td>
                  <td>
                    <Link
                      to={`/admin/sites/${site.id}/keys/${audit.credential}`}
                    >
                      {audit.credential}
                    </Link>
                  </td>
                  <td>{audit.badgeId}</td>
                  <td>{audit.event}</td>
                  <td>
                    <div>{audit.actor?.name}</div>
                    <div style={{ fontSize: 14, color: "gray" }}>
                      {audit.actor?.auth0UserId}
                    </div>
                  </td>
                  <td>
                    <div>{audit.oldAssignment?.name}</div>
                    <div style={{ fontSize: 14, color: "gray" }}>
                      {audit.oldAssignment?.id}
                    </div>
                  </td>
                  <td>
                    <div>{audit.newAssignment?.name}</div>
                    <div style={{ fontSize: 14, color: "gray" }}>
                      {audit.newAssignment?.id}
                    </div>
                  </td>
                  <td>
                    {audit.encoding && (
                      <AuditEncoding encoding={audit.encoding} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ padding: "10px 0" }}>
            <PageControl
              onNext={() => setPage(page + 1)}
              onPrev={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
              setPageSize={(pageSize) => {
                setPageSize(pageSize);
                setPage(0);
              }}
              pageSize={pageSize || defaultPageSize}
              currentPage={page}
              disablePrev={page === 0}
              disableNext={data?.totalCount <= page * pageSize}
            />
          </div>
        </div>
      </div>
    );
  }

  return <div>loading...</div>;
}

function AuditEncoding({
  encoding,
}: {
  encoding: SiteKeyAuditResponse["encoding"];
}) {
  const [showModal, setShowModal] = React.useState(false);

  if (encoding?.addAccess.length === 0 && encoding?.removeAccess.length === 0) {
    return <div>No encoding</div>;
  }

  if (encoding)
    if (encoding.addAccess.length <= 1 && encoding.removeAccess.length <= 1) {
      return (
        <div>
          {encoding.addAccess.length > 0 ? (
            <div>Add Access: {encoding.addAccess.map((aa) => aa.name)}</div>
          ) : null}
          {encoding.removeAccess.length > 0 ? (
            <div>
              Remove Access: {encoding.removeAccess.map((ra) => ra.name)}
            </div>
          ) : null}
        </div>
      );
    }

  return (
    <>
      <button className="btn" onClick={() => setShowModal(true)}>
        View
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Encoding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {encoding && encoding.addAccess.length > 0 && (
            <div>
              <h3>Add Access</h3>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {encoding?.addAccess.map((aa) => (
                  <li key={aa.noTourId} style={{ marginBottom: 10 }}>
                    <div>Name: {aa.name}</div>
                    <div>No Tour Id: {aa.noTourId}</div>
                    <div>
                      Valid from: {new Date(aa.validFrom).toLocaleString()}
                    </div>
                    <div>Valid to: {new Date(aa.validTo).toLocaleString()}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {encoding && encoding.removeAccess.length > 0 && (
            <div>
              <h3>Remove Access</h3>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {encoding?.removeAccess.map((ra) => (
                  <li key={ra.noTourId} style={{ marginBottom: 10 }}>
                    <div>Name: {ra.name}</div>
                    <div>No Tour Id: {ra.noTourId}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

interface SiteKeyAuditPaginatedResponse {
  totalCount: number;
  data: SiteKeyAuditResponse[];
}

interface SiteKeyAuditResponse {
  id: string;
  timestamp: string;
  credential: string;
  badgeId: string;
  event: string;
  actor: {
    auth0UserId: string;
    name: string;
    avatarUrl: string;
  } | null;
  oldAssignment: {
    name: string;
    id: string;
  } | null;
  newAssignment: {
    name: string;
    id: string;
  } | null;
  encoding: {
    addAccess: {
      name: string;
      noTourId: number;
      validFrom: string;
      validTo: string;
    }[];
    removeAccess: {
      name: string;
      noTourId: number;
    }[];
  } | null;
}

async function getSiteKeyAudit(siteId: string, page: number, pageSize: number) {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("pageSize", pageSize.toString());

  const result = await axios.get<SiteKeyAuditPaginatedResponse>(
    `${BASE_API_URL}/api/sites/${siteId}/physicalKeys/audit?${params.toString()}`
  );

  return result.data;
}

function useGetSiteKeyAudit(siteId: string, page: number, pageSize: number) {
  return useQuery({
    queryKey: ["sites", "keys", "audit", siteId, page, pageSize],
    queryFn: () => getSiteKeyAudit(siteId, page, pageSize),
  });
}
