import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { PageControl } from "components/shared/PageControl";
import { defaultPageSize } from "helpers";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function LockEvents({ lockId }: { lockId: string }) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const { data, isError } = useGetLockEvents(lockId, page, pageSize);

  if (isError) {
    return <div>Error loading events</div>;
  }

  if (data) {
    if (data.length === 0) {
      return <div>No events found</div>;
    }

    return (
      <div style={{ paddingBottom: 20 }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Event</th>
              <th>Time</th>
              <th>Description</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((event, index) => (
              <tr key={index}>
                <td>{event.event}</td>
                <td>{new Date(event.time).toLocaleString()}</td>
                <td>{event.description}</td>
                <td>{event.data}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ padding: "10px 0" }}>
          <PageControl
            onNext={() => setPage(page + 1)}
            onPrev={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            setPageSize={(pageSize) => {
              setPageSize(pageSize);
              setPage(0);
            }}
            pageSize={pageSize || defaultPageSize}
            currentPage={page}
            disablePrev={page === 0}
            disableNext={data.length < pageSize}
          />
        </div>
      </div>
    );
  }

  return <div>loading...</div>;
}

interface LockEventResponse {
  event: string;
  time: string;
  description: string;
  data: string;
}

async function getLockEvents(lockId: string, page: number, pageSize: number) {
  const params = new URLSearchParams();
  const skip = page * pageSize;
  params.append("skip", skip.toString());
  params.append("take", pageSize.toString());

  const response = await axios.get<LockEventResponse[]>(
    `${BASE_API_URL}/api/Locks/${lockId}/events?${params}`
  );
  return response.data;
}

function useGetLockEvents(lockId: string, page: number, pageSize: number) {
  return useQuery({
    queryKey: ["locks", "details", "events", lockId, page, pageSize],
    queryFn: () => getLockEvents(lockId, page, pageSize),
  });
}
