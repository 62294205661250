import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function GuestKeyRequests({ guestId }: { guestId: string }) {
  const { data, isError } = useGetGuestKeyRequests(guestId);

  if (isError) {
    return <div>There as an error loading key requests.</div>;
  }

  if (data) {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Site</th>
              <th>Status</th>
              <th>Credential</th>
            </tr>
          </thead>
          <tbody>
            {data.map((keyRequest) => (
              <tr key={keyRequest.id}>
                <td>
                  <div>{new Date(keyRequest.timestamp).toLocaleString()}</div>
                  <div style={{ fontSize: 12 }}>{keyRequest.id}</div>
                </td>
                <td>
                  <div>{keyRequest.site.name}</div>
                  <div style={{ fontSize: 12 }}>{keyRequest.site.id}</div>
                </td>
                <td>
                  <div>{keyRequest.status}</div>
                  {keyRequest.error ? (
                    <div style={{ color: "red", fontWeight: 500 }}>
                      {keyRequest.error}
                    </div>
                  ) : null}
                </td>
                <td>
                  {keyRequest.credential ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: 250,
                        overflow: "hidden",
                      }}
                      title={keyRequest.credential}
                    >
                      Credential: {keyRequest.credential}
                    </div>
                  ) : null}
                  {keyRequest.credentialId ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: 250,
                        overflow: "hidden",
                      }}
                      title={keyRequest.credentialId}
                    >
                      Credential Id: {keyRequest.credentialId}
                    </div>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div>loading...</div>;
}

export interface GuestKeyRequestsResponse {
  details: string;
  error: null;
  id: string;
  site: {
    id: string;
    name: string;
    systemType: string;
  };
  name: string;
  systemType: string;
  status: string;
  timestamp: string;
  validFrom: string | null;
  validTo: string | null;
  credential: string | null;
  credentialId: string | null;
}

async function getGuestKeyRequests(guestId: string) {
  const url = `${BASE_API_URL}/api/guest/${guestId}/key-requests`;
  const response = await axios.get<GuestKeyRequestsResponse[]>(url);

  return response.data;
}

function useGetGuestKeyRequests(guestId: string) {
  return useQuery({
    queryKey: ["guests", "key-requests", guestId],
    queryFn: () => getGuestKeyRequests(guestId),
  });
}
