import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { FormEvent, useState } from "react";
import { BASE_API_URL } from "store/Me";

export default function DeviceTypeFirmware({
  siteId,
  modelType,
}: {
  siteId?: string;
  modelType: string | undefined;
}) {
  const [isDirty, setIsDirty] = useState(false);
  const { data } = useGetGatewayFirmware(siteId, modelType);
  const postGatewayFirmwareMutation = usePostGatewayFirmware();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const firmwareVersion = formData.get("firmwareVersion") as string;
    const firmwareLink =
      data?.find((firmware) => firmware.version === firmwareVersion)
        ?.links[0] ?? "";

    try {
      postGatewayFirmwareMutation.mutate({
        siteId: siteId!,
        deviceType: modelType!,
        firmwareVersion,
        firmwareLink,
      });
    } catch (e) {
      console.log("ERROR ", e);
    }
  };

  if (data) {
    return (
      <div style={{ marginTop: 12 }}>
        <form onSubmit={onSubmit}>
          <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 8 }}>
            {modelType}
          </div>
          <select
            className="form-control"
            name="firmwareVersion"
            onChange={() => {
              setIsDirty(true);
            }}
          >
            {data.map((firmware) => (
              <option key={firmware.name} value={firmware.version}>
                {firmware.description}
              </option>
            ))}
          </select>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              marginTop: 12,
            }}
          >
            <button
              disabled={!isDirty || postGatewayFirmwareMutation.isLoading}
              className="btn btn-primary"
              type="submit"
            >
              Queue Firmware Update
            </button>
            {postGatewayFirmwareMutation.isLoading ? (
              <div>Updating...</div>
            ) : null}
            {postGatewayFirmwareMutation.isError ? (
              <div>Error updating firmware</div>
            ) : null}
          </div>
        </form>
      </div>
    );
  }

  return null;
}

interface SiteFirmwareResponse {
  description: string;
  deviceType: "gwe" | "cte";
  extendedVersion: string;
  isPublic: boolean;
  links: string[];
  name: string;
  releaseDate: string;
  version: string;
}

async function getGatewayFirmware(
  siteId: string | undefined,
  deviceType: string | undefined
) {
  const result = await axios.get<SiteFirmwareResponse[]>(
    `${BASE_API_URL}/api/sites/${siteId}/${deviceType}/firmware`
  );

  return result.data;
}

function useGetGatewayFirmware(
  siteId: string | undefined,
  deviceType: string | undefined
) {
  return useQuery({
    queryKey: ["sites", "firmware", siteId, deviceType],
    queryFn: () => getGatewayFirmware(siteId, deviceType),
    enabled: !!siteId && !!deviceType,
  });
}

async function postGatewayFirmare(
  siteId: string,
  deviceType: string,
  firmwareVersion: string,
  firmwareLink: string
) {
  const result = await axios.post(
    `${BASE_API_URL}/api/Locks/${siteId}/gateway/${deviceType}/firmware`,
    {
      version: firmwareVersion,
      link: firmwareLink,
    }
  );

  return result.data;
}

function usePostGatewayFirmware() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      siteId,
      deviceType,
      firmwareVersion,
      firmwareLink,
    }: {
      siteId: string;
      deviceType: string;
      firmwareVersion: string;
      firmwareLink: string;
    }) => postGatewayFirmare(siteId, deviceType, firmwareVersion, firmwareLink),
    onSuccess: () => {
      queryClient.invalidateQueries(["locks", "gateway"]);
      queryClient.invalidateQueries(["sites", "firmware"]);
    },
  });
}
