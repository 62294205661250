import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import AdminAccountsContainer from "./components/Admin/AdminAccountsContainer";
import AdminSharedAccountCredentials from "./components/Admin/AdminSharedAccountCredentials";
import AdminClientUsers from "./components/Admin/AdminClientUsers";
import AdminSiteUsers from "./components/Admin/AdminSiteUsers";
import OpenDoor from "./components/KeyRequests/OpenDoor";
import KeyRequests from "./components/KeyRequests/KeyRequests";
import { Auth0 } from "./components/shared/Auth0";
import { Auth0Callback } from "./components/shared/Auth0Callback";
import GuestInvite from "./components/KeyRequests/GuestInvite";
import ScreenInvite from "./components/ScreenInvites/ScreenInvite";
import AllInvites from "./components/KeyRequests/AllInvites";
import FromMeInvites from "./components/KeyRequests/FromMeInvites";
import FromMeAccessLog from "./components/KeyRequests/FromMeAccessLog";
import AdminAccessControlGroups from "./components/Admin/AdminAccessControlGroups";
import AccessControlGroupDetail from "./components/AccessControlGroups/AccessControlGroup";

import Sites from "components/Sites/Sites";
import Locks from "components/Locks/Locks";

import Home from "./components/Home";
import AdminTenetsContainer from "components/Admin/AdminTenetsContainer";
import Guests from "components/AdminGuests/Guests";
import AdminLocks from "components/AdminLocks/Locks";

class App extends React.Component<{}> {
  render() {
    return (
      <Route
        children={({ location, match, history }) => (
          //@ts-ignore
          <Layout match={match} history={history} location={location}>
            <Route exact path="/callback" component={Auth0Callback} />
            <Route exact path="/" component={Home} />
            <Auth0 shouldRenderLogin isForTenantUser={true}>
              <Route
                exact
                path="/admin/accessControlGroups"
                component={AdminAccessControlGroups}
              />
              <Route
                exact
                path="/admin/accessControlGroups/:accessControlGroupId"
                component={AccessControlGroupDetail}
              />
              <Route
                path="/admin/sharedCredentials"
                component={AdminSharedAccountCredentials}
              />
              <Route path="/admin/tenants" component={AdminTenetsContainer} />

              <Route
                path="/admin/accounts"
                component={AdminAccountsContainer}
              />
              <Route path="/admin/clientUsers" component={AdminClientUsers} />

              <Route path="/admin/sites" component={Sites} />
              <Route path="/admin/locks" component={Locks} />

              <Route path="/admin/locks2" component={AdminLocks} />
              <Route path="/admin/guests" component={Guests} />
              <Route path="/admin/siteUsers" component={AdminSiteUsers} />
              <Route path="/openDoor" component={OpenDoor} />
              <Route path="/KeyRequests" component={KeyRequests} />
              <Route exact path="/allInvites" component={AllInvites} />
              <Route exact path="/myInvite" component={GuestInvite} />
              <Route exact path="/myScreenInvite" component={ScreenInvite} />
              <Route exact path="/fromMeInvites" component={FromMeInvites} />
              <Route
                exact
                path="/fromMeAccessLog"
                component={FromMeAccessLog}
              />
            </Auth0>
            <Auth0 shouldRenderLogin={false} isForTenantUser={false}>
              <Route exact path="/myInvite" component={GuestInvite} />
              <Route exact path="/myScreenInvite" component={ScreenInvite} />
              <Route exact path="/fromMeInvites" component={FromMeInvites} />
              <Route
                exact
                path="/fromMeAccessLog"
                component={FromMeAccessLog}
              />
            </Auth0>
            <Redirect to="/" />
          </Layout>
        )}
      />
    );
  }
}

export default App;
