import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function LockAutomation({ lockId }: { lockId: string }) {
  const { data, isError } = useGetLockAutomation(lockId);

  if (isError) {
    return <div>Error loading automations</div>;
  }

  if (data) {
    if (data.length === 0) {
      return <div>No automations found</div>;
    }

    return (
      <div style={{ paddingBottom: 20 }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Time</th>
              <th>Sunday</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
            </tr>
          </thead>
          <tbody>
            {data.map((automation) => (
              <tr key={automation.id}>
                <td>{automation.action}</td>
                <td>{automation.time}</td>
                <td>{automation.sunday ? "Yes" : "No"}</td>
                <td>{automation.monday ? "Yes" : "No"}</td>
                <td>{automation.tuesday ? "Yes" : "No"}</td>
                <td>{automation.wednesday ? "Yes" : "No"}</td>
                <td>{automation.thursday ? "Yes" : "No"}</td>
                <td>{automation.friday ? "Yes" : "No"}</td>
                <td>{automation.saturday ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div>loading...</div>;
}

interface LockAutomationResponse {
  id: string;
  time: number;
  action: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

async function getLockAutomation(lockId: string) {
  const response = await axios.get<LockAutomationResponse[]>(
    `${BASE_API_URL}/api/Locks/${lockId}/automations`
  );
  return response.data;
}

function useGetLockAutomation(lockId: string) {
  return useQuery({
    queryKey: ["locks", "details", "automation", lockId],
    queryFn: () => getLockAutomation(lockId),
  });
}
