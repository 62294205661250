import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccessControlDoorType,
  LockResponse,
  SiteSystemType,
} from "api/ApiClient";
import axios from "axios";
import React, { useState } from "react";
import { SplitButton } from "react-bootstrap";
import { BASE_API_URL } from "store/Me";

export default function Details({ lock }: { lock: LockResponse }) {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="col-md-6">
      <div style={{ marginTop: 24 }} className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Details</h3>
        </div>
        <div className="panel-body">
          {editMode ? (
            <EditDetails lock={lock} setEditMode={setEditMode} />
          ) : (
            <ShowDetails lock={lock} setEditMode={setEditMode} />
          )}
        </div>
      </div>
    </div>
  );
}

function ShowDetails({
  lock,
  setEditMode,
}: {
  lock: LockResponse;
  setEditMode: (editMode: boolean) => void;
}) {
  const [flags, setFlags] = useState<number[]>([]);
  const syncLockMutation = usePostSyncLock(lock.id!);

  const toggleFlag = (flag: number) => {
    if (flags.includes(flag)) {
      setFlags(flags.filter((f) => f !== flag));
    } else {
      setFlags([...flags, flag]);
    }
  };

  const syncLock = async () => {
    const flagsValue = flags.reduce((acc, f) => acc | f, 0);

    await syncLockMutation.mutateAsync(flagsValue);

    console.log(flagsValue);
    //await syncLock(lock.id!, flagsValue);
  };

  return (
    <div>
      <div>
        <label>Lock Name</label>
        <p>{lock.name}</p>
      </div>
      <div style={{ marginTop: 12 }}>
        <label>Door Type</label>
        <p>{lock.doorType}</p>
      </div>
      <div style={{ marginTop: 12 }}>
        <label>No Tour Enabled</label>
        <p>{lock.noTourEnabled ? "No" : "Yes"}</p>
      </div>
      <div
        style={{
          marginTop: 12,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button className="btn btn-default" onClick={() => setEditMode(true)}>
          Edit
        </button>
        {lock.site?.account?.systemType === SiteSystemType.SchlageEngage ? (
          <SplitButton
            disabled={syncLockMutation.isLoading}
            id="sync-button"
            title={syncLockMutation.isLoading ? "Syncing..." : "Sync"}
            className="btn"
            onClick={syncLock}
          >
            {[
              { id: 1, name: "Audits" },
              { id: 2, name: "Config" },
              { id: 4, name: "Database" },
            ].map((item) => (
              <button
                key={item.id}
                onClick={() => toggleFlag(item.id)}
                style={{
                  outline: "none",
                  background: "transparent",
                  border: "none",
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {flags.includes(item.id) ? (
                  <span className="fa fa-check-circle"></span>
                ) : (
                  <span className="fa fa-times-circle"></span>
                )}
                {item.name}
              </button>
            ))}
          </SplitButton>
        ) : null}
      </div>
    </div>
  );
}

function EditDetails({
  lock,
  setEditMode,
}: {
  lock: LockResponse;
  setEditMode: (editMode: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const putLockMutation = usePutLock(lock.id!);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      const data = {
        name: form.lockName.value,
        doorType: form.doorType.value,
        noTourEnabled: form.noTourEnabled.checked,
      };

      await putLockMutation.mutateAsync(data);
      setEditMode(false);
      queryClient.invalidateQueries(["locks"]);
    } catch (e) {
      console.log("ERROR ", e);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <fieldset disabled={putLockMutation.isLoading}>
        <div>
          <label htmlFor="lockName">Name</label>
          <input
            id="lockName"
            className="form-control"
            defaultValue={lock.name}
          />
        </div>
        <div style={{ marginTop: 12 }}>
          <label htmlFor="doorType">Door Type</label>
          <select
            id="doorType"
            className="form-control"
            defaultValue={lock.doorType}
          >
            {Object.keys(AccessControlDoorType)
              .filter((k) => isNaN(Number(k)))
              .map((k, i) => (
                <option key={i} value={k.toLowerCase()}>
                  {k}
                </option>
              ))}
          </select>
        </div>
        <div style={{ marginTop: 12 }}>
          <label htmlFor="noTourEnabled">
            <input
              id="noTourEnabled"
              type="checkbox"
              defaultChecked={lock.noTourEnabled}
            />{" "}
            No Tour Enabled
          </label>
        </div>
        <div style={{ marginTop: 12, display: "flex", gap: 12 }}>
          <button
            className="btn"
            onClick={() => setEditMode(false)}
            type="button"
          >
            Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            {putLockMutation.isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </fieldset>
    </form>
  );
}

async function putLock(
  lockId: string,
  data: Pick<LockResponse, "name" | "doorType" | "noTourEnabled">
) {
  const result = await axios.put<LockResponse>(
    `${BASE_API_URL}/api/locks/${lockId}`,
    data
  );
  return result.data;
}

function usePutLock(lockId: string) {
  return useMutation({
    mutationFn: (
      lock: Pick<LockResponse, "name" | "doorType" | "noTourEnabled">
    ) => putLock(lockId, lock),
  });
}

async function syncLock(lockId: string, flags: number) {
  const result = await axios.post(
    `${BASE_API_URL}/api/locks/${lockId}/sync?flags=${flags}`
  );
  return result.data;
}

function usePostSyncLock(lockId: string) {
  return useMutation({
    mutationFn: (flags: number) => syncLock(lockId, flags),
  });
}
// Compare this snippet from src/components/AdminLocks/AdminLocks.tsx:
