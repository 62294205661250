import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SitesState from "../../store/Sites";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { SiteResponse, SiteSystemType } from "../../api/ApiClient";
import { Spinner } from "./Spinner";
import { SiteSyncingState } from "../../store/Sites";
import { Link } from "react-router-dom";
import SearchInput from "./SearchInput";

interface Injected {
  sites: SitesState.SitesState;
}

type OwnProps = {
  onSelected?: (site: SiteResponse) => any;
  pageSize?: number;
  showFirst?: number | null | undefined;
  selectedSites?: Array<SiteResponse>;
  renderButton?: (site: SiteSyncingState) => any;
  selectedLabel?: string;
  selectedBtnClass?: string;
  unselectedLabel?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected & typeof SitesState.actionCreators & OwnProps;

interface State {
  typedSite: string;
}

class SelectSite extends React.Component<Props, State> {
  state: State = {
    typedSite: "",
  };

  componentDidMount() {
    console.log("SelectSite: Requesting sites...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedSite: searchTerm } = this.state;
      await this.props.setFetchParamSites({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting sites", e);
      alert("Error requesting sites: " + JSON.stringify(e));
    }
  };

  handleTypedSite = (value: string) => {
    this.setState({ typedSite: value }, this.searchSite);
  };

  searchSite = debounce(this.refresh, 1000);

  public render() {
    const { sites, searchTerm, isLoading } = this.props.sites;

    const { typedSite } = this.state;

    return (
      <div>
        <SearchInput
          name="typedSite"
          placeholder="type here"
          value={typedSite}
          onChange={(e) => this.handleTypedSite(e.target.value)}
        />
        <h4>
          {searchTerm ? `Sites Matching '${searchTerm}'` : "Sites"}
          {isLoading && <Spinner />}
        </h4>
        {this.renderSitesTable(sites)}
      </div>
    );
  }

  private defaultRenderButton = (site: SiteResponse) => {
    const {
      selectedSites,
      onSelected,
      btnStyle,
      selectedBtnClass,
      selectedLabel,
      unselectedBtnClass,
      unselectedLabel,
    } = this.props;
    const isSelected =
      selectedSites && selectedSites.find((s) => s.id === site.id);
    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";
    return (
      <button
        type="button"
        className={`btn ${className}`}
        style={btnStyle}
        onClick={() => onSelected && onSelected(site)}
      >
        {!isSelected
          ? unselectedLabel || "Select"
          : selectedLabel || "Unselect"}
      </button>
    );
  };

  private renderSitesTable(sites: SiteSyncingState[]) {
    const { renderButton, showFirst } = this.props;
    const { isLoading } = this.props.sites;
    sites = !showFirst ? sites : (sites || []).slice(0, showFirst);

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Id</th>
              <th>SystemSideId</th>
              <th>Name</th>
              <th>SystemType</th>
              <th>PropertyExternalId</th>
              <th>Account</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {sites.map((site, i) => (
              <tr key={"f_" + i}>
                <td>{site.id}</td>
                <td>{site.systemSiteId}</td>
                <td>{site.name}</td>
                <td>{site.account ? site.account.systemType : ""}</td>
                <td>{site.propertyExternalId}</td>
                <td>{site.account ? site.account.name : ""}</td>
                <td style={{ width: 100 }}>
                  <React.Fragment>
                    {renderButton
                      ? renderButton(site)
                      : this.defaultRenderButton(site)}
                    <Link
                      className="btn btn-block btn-default"
                      style={{ marginTop: "5px" }}
                      to={`/admin/sites/${site.id}/guests?filterOperation=GreaterThanOrEqualTo&filterProperty=ValidTo`}
                    >
                      Guests
                    </Link>
                    <Link
                      className="btn btn-block btn-default"
                      to={`/admin/sites/${site.id}/accessschedules`}
                    >
                      Access Schedules
                    </Link>
                    <Link
                      className="btn btn-block btn-default"
                      to={`/admin/sites/${site.id}/edit`}
                    >
                      Edit Site
                    </Link>
                    {site.account?.systemType ===
                      SiteSystemType.SchlageEngage && (
                      <>
                        <Link
                          className="btn btn-block btn-default"
                          to={`/admin/sites/${site.id}/keys`}
                        >
                          Keys
                        </Link>

                        <Link
                          className="btn btn-block btn-default"
                          to={{
                            pathname: `/admin/sites/${site.id}/keys-audit`,
                            state: { site },
                          }}
                        >
                          Audit
                        </Link>
                      </>
                    )}
                  </React.Fragment>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!showFirst && (
          <PageControl
            onNext={this.props.nextSitePage}
            onPrev={this.props.prevSitePage}
            currentPage={this.props.sites.page}
            isLoading={isLoading}
            disablePrev={this.props.sites.page === 0}
            disableNext={
              this.props.sites.sites.length < this.props.sites.pageSize
            }
          />
        )}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    sites: state.sites,
  }),
  { ...SitesState.actionCreators }
)(SelectSite);
