import React from "react";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { GuestResponse } from "./AdminGuests";
import { BASE_API_URL } from "store/Me";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import GuestInvites from "./GuestInvites";
import GuestCredentials from "./GuestCredentials";
import GuestKeyRequests from "./GuestKeyRequests";
import GuestKeyRings from "./GuestKeyRings";
import GuestLockActivity from "./GuestLockActivity";

const navLinks = [
  { to: "invites", label: "Invites" },
  { to: "credentials", label: "Credentials" },
  { to: "key-requests", label: "Key Requests" },
  { to: "lock-activity", label: "Lock Activity" },
];

export default function AdminGuest() {
  const params = useParams<{ guestId: string }>();
  const { url } = useRouteMatch();
  const { data } = useGetGuest(params.guestId!);

  if (data) {
    return (
      <div style={{ paddingTop: 36 }}>
        <Link to="/admin/guests">Back to Guests</Link>
        <Details guest={data} />
        <GuestKeyRings guestId={data.id} />
        <Navigation />
        <div style={{ paddingTop: 12 }}>
          <Switch>
            <Route path={`${url}/invites`}>
              <GuestInvites guestId={data.id} />
            </Route>
            <Route path={`${url}/credentials`}>
              <GuestCredentials guestId={data.id} />
            </Route>
            <Route path={`${url}/key-requests`}>
              <GuestKeyRequests guestId={data.id} />
            </Route>
            <Route path={`${url}/lock-activity`}>
              <GuestLockActivity guestId={data.id} />
            </Route>
            <Redirect to={`${url}/invites`} />
          </Switch>
        </div>
      </div>
    );
  }

  return <div>loading...</div>;
}

function Details({ guest }: { guest: GuestResponse }) {
  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <h4>
          {guest.firstName} {guest.lastName}
        </h4>
        <div
          style={{
            padding: "1px 4px",
            border: "1px solid",
            borderColor: guest.deletedTimestamp ? "red" : "green",
            color: guest.deletedTimestamp ? "red" : "green",
            backgroundColor: guest.deletedTimestamp ? "pink" : "lightgreen",
            fontWeight: 500,
            borderRadius: 4,
            fontSize: 12,
          }}
        >
          {guest.deletedTimestamp ? "Deleted" : "Active"}
        </div>
      </div>
      <p>
        <a href={`mailto:${guest.email}`}>{guest.email}</a>
      </p>
      <div style={{ fontSize: 12, color: "gray" }}>
        Valid {new Date(guest.validFrom).toLocaleString()} to{" "}
        {new Date(guest.validTo).toLocaleString()}
      </div>
    </div>
  );
}

function Navigation() {
  const { url } = useRouteMatch();

  return (
    <div style={{ marginTop: 20 }}>
      <nav style={{ display: "flex", alignItems: "center", gap: 12 }}>
        {navLinks.map(({ to, label }) => (
          <NavLink
            key={to}
            to={`${url}/${to}`}
            activeStyle={{
              fontWeight: 600,
              backgroundColor: "rgb(95, 165, 249)",
              padding: "4px 8px",
              color: "white",
              borderRadius: 4,
              textDecoration: "none",
            }}
          >
            {label}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

async function getGuest(guestId: string) {
  const url = `${BASE_API_URL}/api/guest/${guestId}`;
  const response = await axios.get<GuestResponse>(url);

  return response.data;
}

function useGetGuest(guestId: string) {
  return useQuery({
    queryKey: ["guests", "details", guestId],
    queryFn: () => getGuest(guestId),
  });
}
