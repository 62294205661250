import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import AdminLocks from "./AdminLocks";
import AdminLock from "./AdminLock";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

export default function Locks() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [doorType, setDoorType] = useState("");
  const [siteId, setSiteId] = useState("");
  const [search, setSearch] = useState("");
  const isSuperUser = useSelector(
    (state: ApplicationState) => state.me.user?.superUser
  );

  if (!isSuperUser) {
    return (
      <div style={{ marginTop: 24, fontWeight: 500, color: "red" }}>
        Access Denied: You do not have permission to access this page
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/admin/locks2">
        <AdminLocks
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          doorType={doorType}
          setDoorType={setDoorType}
          siteId={siteId}
          setSiteId={setSiteId}
        />
      </Route>
      <Route path="/admin/locks2/:lockId" component={AdminLock} />
    </Switch>
  );
}
