import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import SearchInput from "components/shared/SearchInput";
import React from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL } from "store/Me";
import useDebounce from "utils/useDebounce";

export default function AdminGuests({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (search: string) => void;
}) {
  const debouncedSearchTerm = useDebounce(search, 500);
  const { data, isLoading, isError } = useGetGuests(debouncedSearchTerm);

  return (
    <div>
      <h3>Guests</h3>
      <div>
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {isError ? (
          <div>There was an error</div>
        ) : search.trim().length === 0 ? (
          <div>No Guests Found</div>
        ) : isLoading ? (
          <div>loading...</div>
        ) : data ? (
          <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>Deleted</th>
                </tr>
              </thead>
              <tbody>
                {data.map((guest) => (
                  <tr key={guest.id}>
                    <td>
                      <div>
                        <Link
                          to={{
                            pathname: `/admin/guests/${guest.id}`,
                            state: { guest },
                          }}
                        >
                          {guest.firstName} {guest.lastName}
                        </Link>
                      </div>
                      <div
                        style={{ paddingTop: 4, fontSize: 12, color: "gray" }}
                      >
                        {guest.id}
                      </div>
                    </td>
                    <td>{guest.email}</td>
                    <td>{guest.validFrom}</td>
                    <td>{guest.validTo}</td>
                    <td>{guest.deletedTimestamp ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export interface GuestResponse {
  deletedTimestamp: string | null;
  email: string | null;
  firstName: string | null;
  id: string;
  lastName: string | null;
  validFrom: string;
  validTo: string;
}

async function getGuests(search: string) {
  const url = `${BASE_API_URL}/api/guest/search?search=${search}`;
  const response = await axios.get<GuestResponse[]>(url);

  return response.data;
}

function useGetGuests(search: string) {
  return useQuery({
    queryKey: ["guests", search],
    queryFn: () => getGuests(search),
    enabled: !!search,
  });
}
