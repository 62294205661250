import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminGuests from "./AdminGuests";
import AdminGuest from "./AdminGuest";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

export default function Guests() {
  const [search, setSearch] = React.useState("");
  const isSuperUser = useSelector(
    (state: ApplicationState) => state.me.user?.superUser
  );

  if (!isSuperUser) {
    return (
      <div style={{ marginTop: 24, fontWeight: 500, color: "red" }}>
        Access Denied: You do not have permission to access this page
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path="/admin/guests">
        <AdminGuests search={search} setSearch={setSearch} />
      </Route>
      <Route path="/admin/guests/:guestId" component={AdminGuest} />
    </Switch>
  );
}
