import { useQuery } from "@tanstack/react-query";
import { AccessControlDoorType, SiteSystemType } from "api/ApiClient";
import axios from "axios";
import React from "react";
import { Badge, Button } from "react-bootstrap";
import { BASE_API_URL } from "store/Me";

export default function GuestKeyRings({ guestId }: { guestId: string }) {
  const [showSites, setShowSites] = React.useState(false);
  const { data, isError } = useGetGuestKeyRings(guestId);

  if (isError) {
    return <div>There was an error</div>;
  }

  if (data) {
    return (
      <div style={{ marginTop: 24 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <h4>Sites</h4>
          <Button bsSize="small" onClick={() => setShowSites(!showSites)}>
            {showSites ? "Hide" : "Show"} Sites
          </Button>
        </div>
        {showSites ? (
          <div style={{ marginTop: 12 }}>
            {data.sites.map((site) => (
              <div key={site.id}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <strong>{site.name}</strong>
                  <Badge type="secondary">{site.systemType}</Badge>
                </div>
                <div style={{ paddingTop: 12, paddingLeft: 16 }}>
                  <h6>Groups</h6>
                  {site.groups.map((group) => (
                    <div key={group.id} style={{ paddingTop: 8 }}>
                      <div>
                        <strong>{group.name}</strong>
                        <p style={{ fontSize: 14 }}>{group.id}</p>
                        <p style={{ fontSize: 12 }}>
                          Valid: {new Date(group.validFrom).toLocaleString()} to{" "}
                          {new Date(group.validTo).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ paddingTop: 12, paddingLeft: 16 }}>
                  <h6>Locks</h6>
                  {site.locks.map((lock) => (
                    <div key={lock.id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <strong>{lock.name}</strong>
                        <Badge type="secondary">{lock.doorType}</Badge>
                      </div>
                      <p style={{ fontSize: 14 }}>{lock.systemLockId}</p>
                      <p style={{ fontSize: 12 }}>
                        Valid: {new Date(lock.validFrom).toLocaleString()} to{" "}
                        {new Date(lock.validTo).toLocaleString()}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  return <div>loading...</div>;
}

interface GuestKeyRingResponse {
  validFrom: string;
  validTo: string;
  sites: [
    {
      id: string;
      name: string;
      systemType: SiteSystemType;
      groups: [
        {
          id: string;
          name: string;
          validFrom: string;
          validTo: string;
        }
      ];
      locks: [
        {
          id: string;
          name: string;
          systemLockId: string;
          validFrom: string;
          validTo: string;
          doorType: AccessControlDoorType;
          hasCurrentAccess: true;
        }
      ];
      validFrom: string;
      validTo: string;
    }
  ];
}

async function getGuestKeyRings(guestId: string) {
  const url = `${BASE_API_URL}/api/guest/${guestId}/key-ring`;
  const response = await axios.get<GuestKeyRingResponse>(url);

  return response.data;
}

function useGetGuestKeyRings(guestId: string) {
  return useQuery({
    queryKey: ["guests", "key-rings", guestId],
    queryFn: () => getGuestKeyRings(guestId),
  });
}
