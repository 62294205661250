import React from "react";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { SiteKeyResponse } from "./Keys";
import axios from "axios";
import { BASE_API_URL } from "store/Me";
import { useQuery } from "@tanstack/react-query";
import SiteKeyAudit from "./KeyAudit";
import SiteKeyActivity from "./Activity";

export default function SiteKey() {
  const { url } = useRouteMatch();
  const params = useParams<{ siteId: string; credentialId: string }>();
  const { data, isError } = useGetSiteKey(params.siteId, params.credentialId);

  if (isError) {
    return <div>Error loading key</div>;
  }

  if (data) {
    return (
      <div style={{ paddingTop: 36 }}>
        <Link to={`/admin/sites/${params.siteId}/keys`}>Back to Keys</Link>
        <div style={{ fontSize: 14, color: "gray", marginTop: 12 }}>
          credential
        </div>
        <div style={{ fontSize: 24, fontWeight: 600 }}>{data.credential}</div>
        <div style={{ fontSize: 14, color: "gray", marginTop: 4 }}>
          badge id
        </div>
        <div style={{ fontSize: 24, fontWeight: 600 }}>{data.badgeId}</div>
        <Navigation />
        <div style={{ paddingTop: 12 }} className="row">
          <div className="col-xs-10">
            <Switch>
              <Route path={`${url}/activity`}>
                <SiteKeyActivity
                  siteId={params.siteId}
                  credentialId={params.credentialId}
                />
              </Route>
              <Route path={`${url}/audit`}>
                <SiteKeyAudit
                  siteId={params.siteId}
                  credentialId={params.credentialId}
                />
              </Route>
              <Redirect to={`${url}/activity`} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  return <div>Loading...</div>;
}

const navLinks = [
  { to: "activity", label: "Activity" },
  { to: "audit", label: "Audit" },
];

function Navigation() {
  const { url } = useRouteMatch();

  return (
    <div style={{ marginTop: 20 }}>
      <nav style={{ display: "flex", alignItems: "center", gap: 12 }}>
        {navLinks.map(({ to, label }) => (
          <NavLink
            key={to}
            to={`${url}/${to}`}
            activeStyle={{
              fontWeight: 600,
              backgroundColor: "rgb(95, 165, 249)",
              padding: "4px 8px",
              color: "white",
              borderRadius: 4,
              textDecoration: "none",
            }}
          >
            {label}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

async function getKey(siteId: string, credentialId: string) {
  const result = await axios.get<SiteKeyResponse>(
    `${BASE_API_URL}/api/sites/${siteId}/physicalKeys/${credentialId}`
  );
  return result.data;
}

function useGetSiteKey(siteId: string, credentialId: string) {
  return useQuery({
    queryKey: ["keys", siteId, credentialId],
    queryFn: () => getKey(siteId, credentialId),
  });
}
