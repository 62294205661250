import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL } from "store/Me";

export default function LockGuests({ lockId }: { lockId: string }) {
  const { data, isError } = useGetLockGuests(lockId);

  if (isError) {
    return <div>Error loading guests</div>;
  }

  if (data) {
    if (data.length === 0) {
      return <div>No guests found</div>;
    }

    return (
      <div style={{ paddingBottom: 20 }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Deleted</th>
            </tr>
          </thead>
          <tbody>
            {data.map((guest) => (
              <tr key={guest.id}>
                <td>
                  <Link to={`/admin/guests/${guest.id}`}>
                    {guest.firstName} {guest.lastName}
                  </Link>
                  <div style={{ paddingTop: 4, fontSize: 12, color: "gray" }}>
                    {guest.id}
                  </div>
                </td>
                <td>{guest.email}</td>
                <td>{new Date(guest.validFrom).toLocaleString()}</td>
                <td>{new Date(guest.validTo).toLocaleString()}</td>
                <td>{guest.deletedTimestamp ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div>loading...</div>;
}

interface LockGuestResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  validFrom: string;
  validTo: string;
  deletedTimestamp: string;
}

async function getLockGuests(lockId: string) {
  const response = await axios.get<LockGuestResponse[]>(
    `${BASE_API_URL}/api/Locks/${lockId}/guests`
  );
  return response.data;
}

function useGetLockGuests(lockId: string) {
  return useQuery({
    queryKey: ["locks", "details", "guests", lockId],
    queryFn: () => getLockGuests(lockId),
  });
}
