import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function GuestInvites({ guestId }: { guestId: string }) {
  const { data, isError } = useGetGuestInvites(guestId);

  if (isError) {
    return <div>There as an error loading invites.</div>;
  }

  if (data) {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Valid</th>
              <th>Note</th>
              <th>Deactivated</th>
            </tr>
          </thead>
          <tbody>
            {data.map((invite) => (
              <tr key={invite.id}>
                <td>
                  <div>{new Date(invite.timestamp).toLocaleString()}</div>
                  <div style={{ fontSize: 12 }}>{invite.id}</div>
                </td>
                <td>
                  <div>From: {new Date(invite.validFrom).toLocaleString()}</div>
                  <div>To: {new Date(invite.validTo).toLocaleString()}</div>
                </td>
                <td>{invite.note}</td>
                <td>{invite.deactivatedTimestamp ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div>loading...</div>;
}

export interface GuestInviteResponse {
  id: string;
  timestamp: string;
  updatedTimestamp: string;
  validFrom: string;
  validTo: string;
  note: string;
  deactivatedTimestamp: string;
}

async function getGuestInvites(guestId: string) {
  const url = `${BASE_API_URL}/api/guest/${guestId}/invites`;
  const response = await axios.get<GuestInviteResponse[]>(url);

  return response.data;
}

function useGetGuestInvites(guestId: string) {
  return useQuery({
    queryKey: ["guests", "invites", guestId],
    queryFn: () => getGuestInvites(guestId),
  });
}
