import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { PageControl } from "components/shared/PageControl";
import { defaultPageSize } from "helpers";
import React from "react";
import { BASE_API_URL } from "store/Me";

export default function LockActivity({ lockId }: { lockId: string }) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const { data, isError } = useGetLockActivity(lockId, page, pageSize);

  if (isError) {
    return <div>Error loading activity</div>;
  }

  if (data) {
    if (data.totalCount === 0) {
      return <div>No activity found</div>;
    }

    return (
      <div style={{ paddingBottom: 20 }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Timestamp</th>
              <th>Category</th>
              <th>Credentials</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((activity) => (
              <tr key={activity.id}>
                <td>{activity.fullName}</td>
                <td>{new Date(activity.timestamp).toLocaleString()}</td>
                <td>{activity.lockActivityCategory}</td>
                <td>
                  <div>Credential Number: {activity.credentialNumber}</div>
                  <div>Credential Type: {activity.credentialType}</div>
                </td>
                <td>{activity.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ padding: "10px 0" }}>
          <PageControl
            onNext={() => setPage(page + 1)}
            onPrev={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            setPageSize={(pageSize) => {
              setPageSize(pageSize);
              setPage(0);
            }}
            pageSize={pageSize || defaultPageSize}
            currentPage={page}
            disablePrev={page === 0}
            disableNext={data?.totalCount <= page * pageSize}
          />
        </div>
      </div>
    );
  }

  return <div>loading...</div>;
}

interface LockActivityResponse {
  totalCount: number;
  data: {
    id: string;
    guestId: string;
    fullName: string;
    timestamp: string;
    lockActivityCategory: string;
    credentialNumber: string;
    credentialType: string;
    details: string;
  }[];
}

async function getLockActivity(lockId: string, page: number, pageSize: number) {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("pageSize", pageSize.toString());

  const response = await axios.get<LockActivityResponse>(
    `${BASE_API_URL}/api/Locks/${lockId}/lock-activity?${params}`
  );
  return response.data;
}

function useGetLockActivity(lockId: string, page: number, pageSize: number) {
  return useQuery({
    queryKey: ["locks", "details", "activity", lockId, page, pageSize],
    queryFn: () => getLockActivity(lockId, page, pageSize),
  });
}
